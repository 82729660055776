module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"mode":"async","web":[{"name":"Geologica","file":"https://fonts.googleapis.com/css2?family=Geologica:wght@300;400;700;800&display=swap"},{"name":"Roboto","file":"https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"016a57421fed608e5364496e09d44acb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","defaultLanguage":"en","siteUrl":"https://byairapp.com/","i18nextOptions":{"postProcess":"sprintf","fallbackLng":"en","supportedLngs":["en","de","es","fr","ja","ko","ru"],"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/flight","languages":["en","de","es","fr","ja","ko","ru"]},{"matchPath":"/flight_feedback","languages":["en","de","es","fr","ja","ko","ru"]},{"matchPath":"/airport_report","languages":["en","de","es","fr","ja","ko","ru"]},{"matchPath":"/flight_report","languages":["en","de","es","fr","ja","ko","ru"]},{"matchPath":"/app_report","languages":["en","de","es","fr","ja","ko","ru"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
